import { useState } from "react";
import "./CreateNode.css";

const CreateNode = ({ onNodeCreated }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [nodeType, setNodeType] = useState("");
  const [configFields, setConfigFields] = useState([]);
  const [defaultData, setDefaultData] = useState({});

  const nodeTypeConfig = {
    Input: {
      configFields: [
        { name: "inputName", label: "Name", type: "text" },
        {
          name: "inputType",
          label: "Type",
          type: "select",
          options: ["Text", "File"],
        },
      ],
      defaultData: {
        inputName: "",
        inputType: "Text",
      },
      inputs: [],
      outputs: [{ id: "output", position: "right" }],
    },
    Output: {
      configFields: [{ name: "outputName", label: "Name", type: "text" }],
      defaultData: {
        outputName: "",
      },
      inputs: [{ id: "input", position: "left" }],
      outputs: [],
    },
    LLM: {
      configFields: [
        {
          name: "modelName",
          label: "Model Name",
          type: "select",
          options: ["GPT", "Claude", "Google Gemini", "Other"],
        },
      ],
      defaultData: {
        modelName: "GPT",
      },
      inputs: [{ id: "input", position: "left" }],
      outputs: [{ id: "output", position: "right" }],
    },
    Text: {
      configFields: [{ name: "textContent", label: "Content", type: "text" }],
      defaultData: {
        textContent: "",
      },
      inputs: [{ id: "input", position: "left" }],
      outputs: [{ id: "output", position: "right" }],
    },
    Custom: {
      configFields: [
        { name: "name", label: "Name", type: "text" },
        {
          name: "requestType",
          label: "Type",
          type: "select",
          options: ["GET", "POST", "Random"],
        },
        {
          name: "hasInput",
          label: "Input",
          type: "checkbox",
        },
        {
          name: "hasOutput",
          label: "Output",
          type: "checkbox",
        },
      ],
      defaultData: {
        name: "",
        requestType: "GET",
        url: "",
        randomText: "",
        hasInput: true,
        hasOutput: true,
      },
      inputs: [],
      outputs: [],
    },
  };

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setNodeType("");
    setConfigFields([]);
    setDefaultData({});
  };

  const handleNodeTypeChange = (e) => {
    const selectedType = e.target.value;
    setNodeType(selectedType);

    if (nodeTypeConfig[selectedType]) {
      setConfigFields(nodeTypeConfig[selectedType].configFields || []);
      setDefaultData(nodeTypeConfig[selectedType].defaultData || {});
    } else {
      setConfigFields([]);
      setDefaultData({});
    }
  };

  const handleFieldChange = (e, fieldName) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setDefaultData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const renderConditionalFields = () => {
    if (nodeType !== "Custom") {
      return null;
    }

    const requestType = defaultData.requestType;

    if (requestType === "GET" || requestType === "POST") {
      return (
        <div>
          <label>
            URL:
            <input
              type="text"
              value={defaultData.url}
              onChange={(e) => handleFieldChange(e, "url")}
            />
          </label>
        </div>
      );
    } else if (requestType === "Random") {
      return (
        <div>
          <label>
            Random Text:
            <input
              type="text"
              value={defaultData.randomText}
              onChange={(e) => handleFieldChange(e, "randomText")}
            />
          </label>
        </div>
      );
    }
    return null;
  };

  const handleCreateNode = () => {
    if (!nodeType) {
      alert("Please select a node type.");
      return;
    }

    let nodeTypeKey = nodeType.toLowerCase().replace(/\s+/g, "");
    let nodeTypeName = nodeType;

    let inputs = [];
    let outputs = [];


    const timestamp = new Date().getTime();

    if (nodeType === "Custom") {
      if (!defaultData.name || defaultData.name.trim() === "") {
        alert("Please provide a name for the Custom node.");
        return;
      }

      nodeTypeKey = `${defaultData.name.toLowerCase().replace(/\s+/g, "")}-${timestamp}`;
      nodeTypeName = defaultData.name;

      if (defaultData.hasInput) {
        inputs = [{ id: "input", position: "left" }];
      }
      if (defaultData.hasOutput) {
        outputs = [{ id: "output", position: "right" }];
      }

      const filteredConfigFields = configFields.filter(
        (field) => field.name !== "hasInput" && field.name !== "hasOutput"
      );

      const nodeConfig = {
        nodeType: nodeTypeName,
        configFields: filteredConfigFields,
        defaultData,
        inputs,
        outputs,
      };

      const storedNodeConfigurations =
        JSON.parse(localStorage.getItem("nodeConfigurations")) || {};
      

      storedNodeConfigurations[nodeTypeKey] = nodeConfig;
      localStorage.setItem(
        "nodeConfigurations",
        JSON.stringify(storedNodeConfigurations)
      );

      alert(`Custom node "${nodeTypeName}" has been created and saved.`);
    } else {
      nodeTypeKey = `${nodeType.toLowerCase().replace(/\s+/g, "")}-${timestamp}`;
      
      inputs = nodeTypeConfig[nodeType].inputs || [];
      outputs = nodeTypeConfig[nodeType].outputs || [];

      const nodeConfig = {
        nodeType,
        configFields,
        defaultData,
        inputs,
        outputs,
      };

      const storedNodeConfigurations =
        JSON.parse(localStorage.getItem("nodeConfigurations")) || {};
      

      storedNodeConfigurations[nodeTypeKey] = nodeConfig;
      localStorage.setItem(
        "nodeConfigurations",
        JSON.stringify(storedNodeConfigurations)
      );

      alert(`Node type "${nodeType}" has been created and saved.`);
    }

    handleClosePopup();
    onNodeCreated();
  };

  let checkboxFields = [];
  let otherFields = [];

  configFields.forEach((field) => {
    if (
      nodeType !== "Custom" &&
      (field.name === "hasInput" || field.name === "hasOutput")
    ) {
      return;
    }

    if (field.type === "checkbox") {
      checkboxFields.push(
        <label key={field.name}>
          {field.label}:
          <input
            type="checkbox"
            checked={defaultData[field.name]}
            onChange={(e) => handleFieldChange(e, field.name)}
          />
        </label>
      );
    } else {
      otherFields.push(
        <div key={field.name}>
          <label>
            {field.label !== "Input" && field.label !== "Output"
              ? `${field.label}:`
              : null}
            {field.type === "select" ? (
              <select
                value={defaultData[field.name]}
                onChange={(e) => handleFieldChange(e, field.name)}
              >
                {field.options.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={field.type}
                value={defaultData[field.name]}
                onChange={(e) => handleFieldChange(e, field.name)}
              />
            )}
          </label>
        </div>
      );
    }
  });

  return (
    <div>
      <button className="add-new-node-btn" onClick={handleButtonClick}>
        ✨ Add New Node
      </button>

      {showPopup && (
        <div className="create-node-popup">
          <h2>Create New Node</h2>

          <label style={{ width: "100%" }}>
            Node Type:
            <select value={nodeType} onChange={handleNodeTypeChange}>
              <option value="">Select Node Type</option>
              {Object.keys(nodeTypeConfig).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>

          {configFields.length > 0 && (
            <div className="create-node-configs">
              <h3>Configuration</h3>

              {otherFields}

              {renderConditionalFields()}

              {checkboxFields.length > 0 && (
                <div className="create-node-checkboxes">
                  {checkboxFields}
                </div>
              )}
            </div>
          )}

          <div className="create-close-btn">
            <button onClick={handleCreateNode}>Create Node</button>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}

      {showPopup && (
        <div
          onClick={handleClosePopup}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: 999,
          }}
        />
      )}
    </div>
  );
};

export default CreateNode;
