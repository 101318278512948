// toolbar.js
import { useState, useEffect } from "react";
import { DraggableNode } from "./draggableNode.js";
import CreateNode from "./components/CreateNode/CreateNode.js";

export const PipelineToolbar = ({ onNodeCreated }) => {
  const [nodeConfigurations, setNodeConfigurations] = useState({});

  useEffect(() => {
    reloadNodeConfigurations();
  }, [onNodeCreated]);

  useEffect(() => {
    const storedNodeConfigurations =
      JSON.parse(localStorage.getItem("nodeConfigurations")) || {};
    setNodeConfigurations(storedNodeConfigurations);
  }, []);

  const reloadNodeConfigurations = () => {
    const storedNodeConfigurations =
      JSON.parse(localStorage.getItem("nodeConfigurations")) || {};
    setNodeConfigurations(storedNodeConfigurations);
  };

  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        alignItems: "center",
        gap: "50px",
        position: "fixed",
        zIndex: "100",
      }}
    >
      <CreateNode onNodeCreated={onNodeCreated} />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {Object.entries(nodeConfigurations).map(([nodeTypeKey, nodeConfig]) => (
          <DraggableNode type={nodeTypeKey} label={nodeConfig.nodeType} />
        ))}
      </div>
    </div>
  );
};
