import { useState } from "react";
import { useStore } from "./store";
import axios from "axios";
import Swal from "sweetalert2";
import Confetti from "react-dom-confetti";

export const SubmitButton = () => {
  const [confettiActive, setConfettiActive] = useState(false);
  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 30,
    elementCount: 100,
    decay: 0.9,
  };
  const { nodes, edges } = useStore((state) => ({
    nodes: state.nodes,
    edges: state.edges,
  }));

  const handleSubmit = async () => {
    const backendURL =
    window.location.hostname === "localhost"
      ? "http://127.0.0.1:8000"
      : "https://api.rayvectorshift.xyz";
    try {
      const response = await axios.post(
        `${backendURL}/pipelines/parse`,
        {
          nodes,
          edges,
        }
      );

      const { num_nodes, num_edges, is_dag } = response.data;

      Swal.fire({
        title: "Pipeline Parsed Successfully!",
        html: `
          <p><strong>Number of Nodes:</strong> ${num_nodes}</p>
          <p><strong>Number of Edges:</strong> ${num_edges}</p>
          <p><strong>Is DAG:</strong> ${is_dag ? "Yes" : "No"}</p>
        `,
        icon: "success",
        confirmButtonText: "Awesome!",
        background: "#f5f5f5",
        imageUrl: "https://media.giphy.com/media/4JVTF9zR9BicshFAb7/giphy.gif",
        backdrop: `
          rgba(0,0,123,0.4)
          left top
          no-repeat
        `,
        didOpen: () => {
          setConfettiActive(true);
          setTimeout(() => setConfettiActive(false), 3000); 
        },
      });
    } catch (error) {
      console.error("Error submitting the pipeline:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an issue parsing the pipeline.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        type="button"
        onClick={handleSubmit}
        style={{
          border: "none",
          padding: "10px 10px 10px 20px",
          borderRadius: "25px",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
          color: "#ffffff",
          background: "linear-gradient(to right, #4b229d, #247bc7)",
          position: "absolute",
          bottom: "50px",
        }}
      >
        Submit 🎉
      </button>
      <Confetti
        active={confettiActive}
        config={confettiConfig}
        style={{ zIndex: "9999" }}
      />
    </div>
  );
};
