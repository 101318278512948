import { useState } from "react";
import { PipelineToolbar } from "./toolbar";
import { PipelineUI } from "./ui";
import { SubmitButton } from "./submit";

function App() {
  const [nodeTypesUpdated, setNodeTypesUpdated] = useState(false);

  const handleNodeCreated = () => {
    setNodeTypesUpdated((prev) => !prev);
  };
  return (
    <div>
      <PipelineToolbar onNodeCreated={handleNodeCreated} />
      <PipelineUI nodeTypesUpdated={nodeTypesUpdated} />
      <SubmitButton />
    </div>
  );
}

export default App;
