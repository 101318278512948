// draggableNode.js

export const DraggableNode = ({ type, label }) => {

  const onDragStart = (event, nodeType) => {
    const appData = { nodeType }
    event.target.style.cursor = 'grabbing';
    event.dataTransfer.setData('application/reactflow', JSON.stringify(appData));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div
      className={type}
      onDragStart={(event) => onDragStart(event, type)}
      onDragEnd={(event) => (event.target.style.cursor = 'grab')}
      style={{ 
        cursor: 'grab', 
        minWidth: '80px',
        width: "fit-content",
        padding: "10px",
        height: '25px',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column',
        border: "1.5px solid #247bc7",
        borderRadius: '12px',
        background: "white",
      
      }} 
      draggable
    >
        <span style={{ color: '#247bc7', fontWeight: "bold" }}>{label}</span>
    </div>
  );
};
