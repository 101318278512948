import React from 'react';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

const SimpleCodeEditor = ({ value, onChange }) => {
  const highlight = (code) => {
    return Prism.highlight(code, Prism.languages.javascript, 'javascript');
  };


  const handleKeyDown = (e) => {
    const { selectionStart, selectionEnd, value } = e.target;
    const char = e.key;


    const pairs = {
      '{': '}',
      '(': ')',
      '[': ']',
      '"': '"',
      "'": "'",
    };


    if (pairs[char]) {
      e.preventDefault();

      const closingChar = pairs[char];
      const newValue =
        value.substring(0, selectionStart) +
        char +
        closingChar +
        value.substring(selectionEnd);

      onChange(newValue);

      setTimeout(() => {
        e.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
      }, 0);
    }
  };

  return (
    <Editor
    className='code-editor'
      value={value}
      onValueChange={onChange}
      highlight={highlight}
      padding={10}
      style={{
        fontSize: 16,
        width: '200px',
        overflow: 'hidden',
        color: '#000',
        backgroundColor: "white",
        border: "1px solid black"
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SimpleCodeEditor;
