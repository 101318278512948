import { useState } from "react";
import { Handle, Position } from "reactflow";
import SimpleCodeEditor from "../components/CodeEditor/CodeEditor.js";

export const createNode = ({
  nodeType,
  inputs = [],
  outputs = [],
  configFields = [],
  defaultData = {},
}) => {
  return ({ id, data }) => {
    const [state, setState] = useState({ ...defaultData, ...data });
    const [declaredVariables, setDeclaredVariables] = useState({});

    const handleChange = (field) => (input) => {
      let value = input?.target ? input.target.value : input;

      //
      const curlyBracePattern = /\{\{(.*?)\}\}/g;
      let match;
      let processedValue = value;
      let newVariables = {};

    
      while ((match = curlyBracePattern.exec(value)) !== null) {
        const codeInsideBraces = match[1].trim();

       
        const variablePattern =
          /\b(?:const|let|var)?\s*([a-zA-Z_$][a-zA-Z_$0-9]*)\s*=\s*(.*?)(?:;|$)/g;
        let variableMatch;

       
        while (
          (variableMatch = variablePattern.exec(codeInsideBraces)) !== null
        ) {
          const varName = variableMatch[1]; 
          const varValue = variableMatch[2].trim();
          newVariables[varName] = varValue;
        }
      }

    
      const remainingVariables = Object.keys(declaredVariables).reduce(
        (acc, varName) => {
          if (newVariables[varName]) {
            acc[varName] = newVariables[varName]; 
          }
          return acc;
        },
        {}
      );

     
      setState((prevState) => ({
        ...prevState,
        [field]: processedValue,
        variables: {
          ...prevState.variables,
          ...newVariables,
        },
      }));

    
      setDeclaredVariables({
        ...remainingVariables,
        ...newVariables,
      });
    };

    return (
      <div className="node-full-body">
        <div className="node-exterior">
          {Object.keys(declaredVariables).length > 0 && (
            <>
              <p>Variables</p>
              <div className="node-variables">
                {Object.entries(declaredVariables).map(
                  ([varName, varValue]) => (
                    <p
                      key={varName}
                      style={{ color: "#247bc7", fontSize: "15px" }}
                    >
                      {varName} = {varValue || "undefined"}
                    </p>
                  )
                )}
              </div>
            </>
          )}
        </div>
        <div className="node-interior">
          {inputs.map((input, index) => (
            <Handle
              key={index}
              type="target"
              position={input.position || Position.Left}
              id={`${id}-${input.id}`}
              style={input.style || {}}
            />
          ))}

          <div>
            <h3>{nodeType}</h3>
          </div>

          <div className="config-fields">
            {configFields.map((field, index) => (
              <label key={index}>
                <span className="config-fields-label">{field.label}:</span>
                {field.type === "select" ? (
                  <select
                    value={state[field.name]}
                    onChange={(e) => handleChange(field.name)(e)}
                  >
                    {field.options.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field.type === "text" ? (
                  <SimpleCodeEditor
                    value={state[field.name]}
                    onChange={(value) => handleChange(field.name)(value)}
                  />
                ) : (
                  <input
                    type={field.type}
                    value={state[field.name]}
                    onChange={(e) => handleChange(field.name)(e)}
                  />
                )}
              </label>
            ))}
          </div>

          {outputs.map((output, index) => (
            <Handle
              key={index}
              type="source"
              position={output.position || Position.Right}
              id={`${id}-${output.id}`}
              style={output.style || {}}
            />
          ))}
        </div>
      </div>
    );
  };
};
